import React from "react"
import Accordion from "../accordion"
import AccordionItem from "../accordion/item"
import File from "./file"

import styles from "./index.module.scss"

const Downloads = ({ data }: any) => (
  <Accordion>
    {data.map((category: any) => {
      return (
        <AccordionItem
          key={category.node.id}
          title={category.node.categoryName}
        >
          <ul className={styles.expandedContent}>
            {category.node.files.map((item: any, index: number) => {
              return <File key={index} data={item} />
            })}
          </ul>
        </AccordionItem>
      )
    })}
  </Accordion>
)
export default Downloads
