import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import styles from "./index.module.scss"
import { AssetDownloadIcon } from "../svgs/"

const Placeholder = () => <div className={styles.placeholder} />

const File = ({ data }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadClass, setDownloadClass] = useState("")
  const [showPlaceholder, setPlaceholder] = useState(false)

  const handleDownloadClick = (e) => {
    setIsDownloading(true)
    setDownloadClass("loading")

    const timer = setTimeout(() => {
      setDownloadClass("")
    }, 4200)

    return () => {
      clearTimeout(timer)
      setIsDownloading(false)
    }
  }

  const fileName = data.path
    .split("-")
    .splice(1)
    .join("")

  const handleTitle = () => {
    if (data.title === null) {
      return fileName.toLowerCase()
    } else {
      return data.title
    }
  }

  useEffect(() => {
    if (!data.isImage) {
      setPlaceholder(true)
    } else {
      setPlaceholder(false)
    }
  }, [])

  const handleFileFormat = (fileFormat, image) => {
    if (fileFormat === "jpg" || fileFormat === "png") {
      return <img src={image} className={styles.thumbnail} />
    } else {
      return
    }
  }
  return (
    <li className={styles.grid}>
      <div className={styles.cell}>
        {handleFileFormat(data.format, data.url)}
        {showPlaceholder && <Placeholder />}
      </div>
      <div className={styles.cell}>
        <small className="typography__display--exception typography__color--grey">
          {data.createdAt}
        </small>
        <h4 className="typography__display--3  typography__ff--flamaMedium typography__text-trans--none">
          {handleTitle()}
        </h4>
      </div>

      <div className={styles.cell}>
        <AssetDownloadIcon
          link={`${data.url}`}
          onClick={handleDownloadClick}
          className={downloadClass}
        />
      </div>
    </li>
  )
}
export default File
