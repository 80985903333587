import React from "react"
import { navigate, StaticQuery, graphql } from "gatsby"
import netlifyIdentity from "netlify-identity-widget"
import Downloads from "../../components/downloads"

import { isLoggedIn, logout } from "../../services/auth"
import Login from "./login"
import Head from "../../components/global/head"

import ContentBoundary from "../../components/content/boundary"
import HeroIntroBanner from "../../components/modules/hero_intro_banner"

import FormButton from "../../components/buttons/form_button"
import ContentBlock from "../../components/modules/content_block"
import CtaBanner from "../../components/modules/cta_banner"

const Layout = ({ children }) => {
  console.log("isLoggedIn", isLoggedIn())
  return (
    <StaticQuery
      query={graphql`
        query {
          allDatoCmsAssetLibraryContent {
            edges {
              node {
                ctaBannerModule {
                  title
                  backgroundImage {
                    url
                  }
                  ctaLink {
                    __typename
                    ... on DatoCmsPost {
                      slug
                    }
                    __typename
                    ... on DatoCmsProgram {
                      slug
                    }
                  }
                  ctaLabel
                  ctaPageUrl
                }
                modules {
                  id
                  bodyCopy
                  title
                  ctaLabel
                  isRootStoryNews
                  isRootProgram
                  ctaPageUrl
                  ctaLink {
                    ... on DatoCmsPost {
                      slug
                    }
                  }
                }
              }
            }
          }
          allDatoCmsFilecategory(sort: { fields: position }) {
            edges {
              node {
                position
                id
                categoryName
                privateAsset
                files {
                  isImage
                  createdAt
                  url
                  path
                  alt
                  title
                  format
                }
              }
            }
          }
        }
      `}
      // tslint:disable-next-line: jsx-no-lambda
      render={(data) => {
        const ctaBannerModule =
          data.allDatoCmsAssetLibraryContent.edges[0].node.ctaBannerModule

        const handleCtaBannerTitle = () => {
          if (ctaBannerModule) {
            return ctaBannerModule[0].title
          } else {
            return
          }
        }

        const handleCtaBannerLabel = () => {
          if (ctaBannerModule) {
            return ctaBannerModule[0].ctaLabel
          } else {
            return
          }
        }

        const handleCtaBannerCtaLink = () => {
          if (ctaBannerModule) {
            return ctaBannerModule[0].ctaLink
          } else {
            return
          }
        }

        const handleCtaBannerCtaPageUrl = () => {
          if (ctaBannerModule) {
            return ctaBannerModule[0].ctaPageUrl
          } else {
            return
          }
        }

        const handleCtaBannerBackgroundImage = () => {
          if (ctaBannerModule) {
            return ctaBannerModule[0].backgroundImage
          } else {
            return
          }
        }
        let contentModuleData = []
        if (data.allDatoCmsAssetLibraryContent.edges[0].node.modules) {
          contentModuleData =
            data.allDatoCmsAssetLibraryContent.edges[0].node.modules
        }

        const user = netlifyIdentity.currentUser()

        const [logoutLabel, setLogoutLabel] = React.useState("Log Out")

        const publicAssets = data.allDatoCmsFilecategory.edges.filter(
          ({ node }) => !node.privateAsset
        )
        return (
          <>
            <Head
              title="Asset Library and Templates"
              url="https://www.choosetap.com.au/asset-library"
              description="Choose Tap partners can login to gain access to templates and resources. We also have some publicly available resources.Choose Tap partners can login to gain access to templates and resources. We also have some publicly available resources."
              keywords="choose tap login, choose tap log in, choose tap asset library, choose tap portal, choose tap resources, choose tap video, choose tap download
        choose tap login, choose tap log in, choose tap asset library, choose tap portal, choose tap resources, choose tap video, choose tap download"
              bodyClassname="body__asset-library"
            />

            <section className="pages__child pages__asset-library">
              <HeroIntroBanner
                title={`Your asset library`}
                sectionName="Login to"
                themeColor="grey"
              >
                <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                  {isLoggedIn() || user ? (
                    <FormButton
                      onClick={(event) => {
                        setLogoutLabel("...logging out")
                        event.preventDefault()
                        logout(() => {
                          navigate(`/asset-library/logout`)

                          setLogoutLabel("Log out")
                        })
                      }}
                      isLogin={false}
                      label={logoutLabel}
                    />
                  ) : (
                    <Login path="/asset-library/login" />
                  )}
                </div>
              </HeroIntroBanner>

              <main>
                <ContentBoundary>
                  {!isLoggedIn() && (
                    <div className="grids__listing  grids__two-column">
                      {contentModuleData.map((module) => {
                        const {
                          id,
                          title,
                          bodyCopy,
                          ctaLabel,
                          ctaLink,
                          ctaPageUrl,
                          isRootProgram,
                          isRootStoryNews,
                        } = module
                        return (
                          <ContentBlock
                            key={id}
                            title={title}
                            bodyCopy={bodyCopy}
                            ctaLabel={ctaLabel}
                            ctaLink={ctaLink}
                            ctaPageUrl={ctaPageUrl}
                            isRootProgram={isRootProgram}
                            isRootStoryNews={isRootStoryNews}
                          />
                        )
                      })}
                    </div>
                  )}
                </ContentBoundary>

                <ContentBoundary>
                  <h2 className="typography__display--6">Document Library</h2>
                  {!isLoggedIn && <Downloads data={publicAssets} />}
                  {children}
                </ContentBoundary>

                <div className="util__margin-bottom--100" />

                <CtaBanner
                  isFooterInstance={true}
                  title={handleCtaBannerTitle()}
                  ctaLabel={handleCtaBannerLabel()}
                  ctaLink={handleCtaBannerCtaLink()}
                  ctaPageUrl={handleCtaBannerCtaPageUrl()}
                  image={handleCtaBannerBackgroundImage()}
                />
              </main>
            </section>
          </>
        )
      }}
    />
  )
}

export default Layout
