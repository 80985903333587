import React, { useState } from "react"
import styles from "./accordion.module.scss"

const AccordionItem = ({ title, children }) => {
  const [active, setActive] = useState(false)

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setActive(!active)
  }

  return (
    <li className={styles.item}>
      <div className={styles.header} onClick={handleClick}>
        <div>
          <h3
            className={`typography__display--4 typography__ff--flamaBold ${
              active ? "typography__color--blue" : "typography__color--navy"
            }`}
          >
            {title}
          </h3>
        </div>

        <div>
          <button
            type="button"
            onClick={handleClick}
            className={`accordion-btn ${
              !active ? "accordion-btn__expand" : "accordion-btn__collapse"
            }`}
          >
            {active ? "collapse" : "expand"}
          </button>
        </div>
      </div>
      <div className={active ? styles.contentActive : styles.contentHidden}>
        {children}
      </div>
    </li>
  )
}
export default AccordionItem
